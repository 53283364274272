import React from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Carrousel.css'; // Asegúrate de importar el archivo CSS

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: {
        items: 5,
    },
};

interface CarouselProps {
    items: React.ReactNode[];
}

const createItems = (items: React.ReactNode[], handleClick: React.Dispatch<React.SetStateAction<number>>, handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void) => {
    let deltaX = 0;
    let difference = 0;
    const swipeDelta = 20;

    return items.map((item, i) => (
        <div
            key={i}
            data-value={i + 1}
            className="item aspect-ratio"
            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => (deltaX = e.pageX)}
            onMouseUp={(e: React.MouseEvent<HTMLDivElement>) => (difference = Math.abs(e.pageX - deltaX))}
            onDragStart={handleDragStart}
            onClick={() => difference < swipeDelta && handleClick(i)}
        >
            <div className="content">
                {item}
            </div>
        </div>
    ));
};

const Carousel: React.FC<CarouselProps> = ({ items, ...rest }: any) => {
    const carousel = React.useRef<AliceCarousel>(null);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

    const carouselItems = createItems(items, setActiveIndex, handleDragStart);

    const slidePrev = (e?: Event | React.MouseEvent) => {
        const newIndex = (activeIndex - 1 + items.length) % items.length;
        carousel?.current?.slideTo(newIndex);
        setActiveIndex(newIndex);
    };
    const slideNext = (e?: Event | React.MouseEvent) => {
        const newIndex = (activeIndex + 1) % items.length;
        carousel?.current?.slideTo(newIndex);
        setActiveIndex(newIndex);
    };
    const syncActiveIndexForSwipeGestures = (e: EventObject) => setActiveIndex(e.item);

    const onSlideChanged = (e: EventObject) => {
        syncActiveIndexForSwipeGestures(e);
    };

    // const onUpdated = (e: EventObject) => {
    //     console.debug(`onUpdated => Item's position after update: ${e.item}. Event:`, e);
    // };

    React.useEffect(() => {
        setLoading(false); // Do not remove this line, this is a fix to force the render of the carousel correctly!
    }, [carousel]);

    const props = { ...rest, responsive: rest?.responsive || responsive }
    return (
        <>
            <div className='carousel-container-wrapp'>
                <AliceCarousel
                    mouseTracking
                    disableDotsControls
                    disableButtonsControls
                    items={carouselItems}
                    activeIndex={activeIndex}
                    onSlideChanged={onSlideChanged}
                    // onUpdated={onUpdated}
                    ref={carousel}
                    {...props}
                />
                <button className="carousel-button carousel-button-prev" onClick={slidePrev}>
                    &lang;
                </button>
                <button className="carousel-button carousel-button-next" onClick={slideNext}>
                    &rang;
                </button>
            </div>
        </>
    );
};

export default Carousel;
