import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import CalendarSection from "../../../components/calendar/CalendarSection";
import DetailCheckout from "../../../components/detail-checkout/DetailChekout";
import FormInfo from "../../../components/form-info/FormInfo";

import Accordion from "../../../components/commons/Accordion";
import ServiceSection from "../../../components/services/ServiceSection";
import MobileCheckout from "../../../components/mobile-checkout/MobileCheckout";
import Clock from "../../../components/commons/svg/Clock";
import Calendar from "../../../components/commons/svg/Calendar";
import Info from "../../../components/commons/svg/Info";
import BagPurple from "../../../components/commons/svg/BagPurple";
import { setActiveStep } from "../../../reducers/stepperSlice";
import { selectDuration, selectCount } from "../../../reducers/servicesSlice";
import { selectDay, selectTime } from "../../../reducers/calendarSlice";
import {
  bookingServiceAsync,
  selectAceptTermsConditions,
  selectReservation,
} from "../../../reducers/checkoutSlice";
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhone,
  selectEmailValid,
  selectFirstNameValid,
  selectLastNameValid,
  selectPhoneValid,
} from "../../../reducers/customerInfoSlice";
import { setFormValid } from "../../../reducers/validationStepperSlice";
import { getOptionsAsync, selectGallery } from "../../../reducers/optionSlice";

import logoIsotipoBlanco from "../../../assets/img/Flamingo-isotipo-blanco-mobile.png";
import logoHorizontal from "../../../assets/img/Flamingo-logo-horizontal-158x54.png";
import StripeForm from "../../../components/checkout/StripeForm";
// import ButtonCheckoutModal from "../../../components/commons/ButtonCheckoutModal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { defaultCallback } from "../../../utils/defaults";
import Countdown from "../../../components/commons/Countdown";
import { selectSucceeded, selectProcessing } from '../../../reducers/checkoutSlice';
import { ImageList, ImageListItem } from "@mui/material";
import Carrousel from "../../../components/commons/Carrousel/Carrousel";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";

// const scrollCheckout = (minWidthForSmoothScroll = 768) => {

//     if (window.innerWidth >= minWidthForSmoothScroll) {
//       document.getElementById("flg-coutdown-scroll")?.scrollIntoView({ behavior: "smooth" });
//     }
// }

const Gallery = (props: any) => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Carrousel
          {...props} />
      </div>
    </>
  )
};

function ModalCheckout(props: any) {
  const { open, onClose = defaultCallback, stepperNum } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "75%",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="modal-checkout-box">
        <StripeForm stepperNum={stepperNum} />
      </Box>
    </Modal>
  );
}

function ModalGallery(props: any) {
  const { galleryItem, open, onClose = defaultCallback } = props;
  let media;
  let url = galleryItem?.url;
  if (galleryItem?.type === "image") {
    url = galleryItem?.sizes.large?.url || url;
    media = (
      <div className="modal-gallery-item">
        <img src={url} alt="" />
      </div>
    );
  }
  else if (galleryItem?.type === "video") {
    media = (
      <div className="modal-gallery-item">
        <ReactPlayer
          pip
          url={url}
          controls
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        />
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent>
          {media}
        </DialogContent>
      </Dialog>
    </>
  );
}

export function Booking() {
  const count: number = useAppSelector(selectCount);
  const serviceId: number | string = useAppSelector(selectDuration);
  const day: string | null = useAppSelector(selectDay);
  const time: string | null = useAppSelector(selectTime);
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectPhone);
  const firstNameValid = useAppSelector(selectFirstNameValid);
  const lastNameValid = useAppSelector(selectLastNameValid);
  const emailValid = useAppSelector(selectEmailValid);
  const phoneValid = useAppSelector(selectPhoneValid);
  const aceptTermsConditions = useAppSelector(selectAceptTermsConditions);
  const reservation = useAppSelector(selectReservation);
  const succeeded: boolean = useAppSelector(selectSucceeded)
  const processing: boolean = useAppSelector(selectProcessing)
  const gallery: any = useAppSelector(selectGallery);
  const [galleryItems, setGalleryItems] = React.useState<any[]>([]);
  const [galleryItem, setGalleryItem] = React.useState<any>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);

  const [modalGalleryOpen, setModalGalleryOpen] = React.useState<boolean>(false);
  const handleModalGalleryOpen = () => setModalGalleryOpen(true);
  const handleModalGalleryClose = () => {
    setModalGalleryOpen(false);
  };

  // const activeStep = useAppSelector(selectActiveStep)

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOptionsAsync());
    // setGalleryItems(galleryItemForTesting);
  }, [dispatch]);

  useEffect(() => {
    const openGalleryModal = (item: any) => {
      setGalleryItem(item);
      handleModalGalleryOpen();
    }

    const handleMouseDown = (e: any) => {
      setIsDragging(false);
      setStartX(e.clientX);
    };

    const handleMouseMove = (e: any) => {
      if (e.clientX !== startX) {
        setIsDragging(true);
      }
    };

    const handleMouseUp = (e: any, item: any) => {
      if (!isDragging) {
        openGalleryModal(item);
      }
      setIsDragging(false);
    };

    const items: any[] = [];
    gallery?.items?.forEach((item: any, i: number) => {
      let media;
      let url = item?.url;
      if (item?.type === "image") {
        // url = item?.sizes?.medium?.url || url;
        media = (
          <div className="carousel-item" key={i} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={(e) => handleMouseUp(e, item)}>
            <div>
              <img src={url} data-value={i} role="presentation" alt="" />
            </div>
          </div>
        );
      }
      else if (item?.type === "video") {
        media = (
          <div className="carousel-item" key={i} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={(e) => handleMouseUp(e, item)}>
            <div data-value={i}>
              <ReactPlayer
                url={url}
                muted
                loop
                playing
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              />
            </div>
          </div>
        );
      }

      if (media) items.push(media);
    });
    setGalleryItems(items);
  }, [gallery, isDragging, startX]);

  const handleOnChangeStep = (value: number) => {
    if (value === 3) {
      const body = {
        reservationId: reservation?.id,
        serviceId,
        count,
        day,
        time,
        firstName,
        lastName,
        email,
        phone,
        termsConditions: aceptTermsConditions,
      };
      dispatch(bookingServiceAsync(body));
    }
    dispatch(setActiveStep(value));
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (!processing && !succeeded) {
      setOpen(false);
    }
  };

  const components = [
    {
      icon: <Clock />,
      title: "1. Service & duration",
      content: <ServiceSection />,
      conditions: {
        next: () => {
          return !!serviceId && !!count;
        },
      },
    },
    {
      icon: <Calendar />,
      title: "2. Date & departure time",
      content: <CalendarSection />,
      conditions: {
        next: () => {
          return !!day && !!time;
        },
      },
    },
    {
      icon: <Info />,
      title: "3. Your information",
      content: <FormInfo />,
      conditions: {
        next: () => {
          const canNext =
            !!firstName &&
            !!lastName &&
            !!email &&
            !!firstNameValid &&
            !!lastNameValid &&
            !!emailValid &&
            !!phoneValid;

          if (!canNext) {
            dispatch(setFormValid(false));
          } else {
            // scrollCheckout(900)
          }


          return canNext;
        },
      },
    },
    {
      icon: <BagPurple />,
      title: "Checkout",
      content: "",
      showNextButton: false,
      conditions: {
        next: () => {
          const canNext =
            !!firstName &&
            !!lastName &&
            !!email &&
            !!firstNameValid &&
            !!lastNameValid &&
            !!emailValid &&
            !!phoneValid;

          // if (canNext){
          //   scrollCheckout(900)
          // }

          return canNext;
        },
      },
    },
  ];

  return (
    <div>
      <div className="flamingo-container">
        <Container maxWidth="xl">
          <Grid container className="" spacing={0}>
            <Grid md={7} className="flamingo-bg-steps">
              <div className="flg-cenefa__logos">
                <img src={logoHorizontal} alt="" />
                <img src={logoIsotipoBlanco} alt="" />
              </div>
              <div className="countdown--mobile">
                <Countdown />
              </div>
              <Accordion items={components} onChangeStep={handleOnChangeStep} />
            </Grid>
            <Grid md={5} className="flg-desktop-checkout__bg">
              <DetailCheckout
                stepperNum={components.length}
                onClickPay={handleOpen}
              />
            </Grid>
            {galleryItems && galleryItems.length > 0 &&
              <Grid md={12}>
                <Typography className="title-flg-gallery__size MuiTypography-body1">
                  Enjoy with us!
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Gallery
                    items={galleryItems}
                    infinite={gallery?.infinite}
                    autoPlay={gallery?.autoplay}
                    animationDuration={gallery?.animationduration}
                    autoPlayInterval={gallery?.autoplayinterval}
                    animationtype={gallery?.animationtype}
                    responsive={gallery?.responsive}
                  />
                </div>
              </Grid>
            }
          </Grid>
          <div className="flg-mobile-your-booking__container">
            <MobileCheckout
              stepperNum={components.length}
              onClickPay={handleOpen}
            />
          </div>
          <ModalCheckout
            keepMounted={true}
            open={open}
            onClose={handleClose}
            stepperNum={components.length}
          />
          <ModalGallery
            keepMounted={true}
            open={modalGalleryOpen}
            onClose={handleModalGalleryClose}
            galleryItem={galleryItem}
          />
        </Container>
      </div>
    </div>
  );
}
export default function StandardImageList() {
  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
];